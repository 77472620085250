<template>
  <div class="center-div">
    <div class="earth-top-box">
      <div class="time-box">{{ $store.getters.config.data_show_center_vice_title_1 }}</div>
      <dv-border-box-11 :title="topdate" class="zhishu-box">
        <div class="zhishu-box-son">{{ topval }}</div>
      </dv-border-box-11>
    </div>
    <div ref='earthMap' id="earthMap"></div>
    <div class="center-end">
      <svg class="center-svg-container" :width="width" :height="height">
        <defs>
          <filter id="center1" height="150%" width="150%" x="-25%" y="-25%">
            <feMorphology operator="dilate" radius="2" in="SourceAlpha" result="thicken"/>
            <feGaussianBlur in="thicken" stdDeviation="3" result="blurred"/>
            <feFlood flood-color="#df9fff" result="glowColor"/>
            <feComposite in="glowColor" in2="blurred" operator="in" result="softGlowColored"/>
            <feMerge>
              <feMergeNode in="softGlowColored"/>
              <feMergeNode in="SourceGraphic"/>
            </feMerge>
          </filter>
        </defs>
        //标题顶部三角
        <polygon points="30,0 43,0 30,23" fill="#df9fff"/>
        //标题顶部折线
        <polyline stroke="#3270b8" :filter="`url(#center1)`"
                  :points="`34, 29, 49, 5, ${width * 0.4}, 5 , ${width * 0.4}, 49 , 34,49,34,49`"/>

        <text :x="`${width * 0.2}`" y="30" fill="#fff" font-size="22" text-anchor="middle"
              dominant-baseline="middle">
          {{ $store.getters.config.data_show_center_vice_title_2 }}
        </text>
      </svg>
      <div ref='CenterOne' id="CenterOne" class="center-one"></div>
      <div class="center-lower">
        <dv-border-box-10>{{ $store.getters.config.data_show_center_vice_title_3 }}</dv-border-box-10>
        <div class="lower-left">
          <div v-for="(item, ri) in $store.getters.config.data_show_center_vice_date_3_1" :key="ri"
               :class="{ 'lower-left-one': ri == 0 }">{{ item }}
          </div>
        </div>
        <div class="lower-center d-flex jc-between px-3">
          <div class="data-div">
            <div class="title">{{ $store.getters.config.data_show_center_vice_title_4 }}{{
                configHeader[configNum - 1]
              }}
            </div>
            <div class="data-tab">
              <table>
                <tr>
                  <th v-for="(row, ri) in config1.header" :key="ri">
                    {{ row }}
                  </th>
                </tr>
                <tr v-for="(row, ri) in config1.data" :key="ri">
                  <td>
                    {{ row[0] }}
                  </td>
                  <td>
                    {{ row[1] }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="data-div">
            <div class="title">{{ $store.getters.config.data_show_center_vice_title_5 }}{{
                configHeader[configNum]
              }}
            </div>
            <div class="data-tab">
              <table>
                <tr>
                  <th v-for="(row, ri) in config2.header" :key="ri">
                    {{ row }}
                  </th>
                </tr>
                <tr v-for="(row, ri) in config2.data" :key="ri">
                  <td>
                    {{ row[0] }}
                  </td>
                  <td>
                    {{ row[1] }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="data-div">
            <div class="title">{{ $store.getters.config.data_show_center_vice_title_6 }}{{
                configHeader[configNum + 1]
              }}
            </div>
            <div class="data-tab">
              <table>
                <tr>
                  <th v-for="(row, ri) in config3.header" :key="ri">
                    {{ row }}
                  </th>
                </tr>
                <tr v-for="(row, ri) in config3.data" :key="ri">
                  <td>
                    {{ row[0] }}
                  </td>
                  <td>
                    {{ row[1] }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="lower-right">
          <div class="title d-flex aside-width">
            <div class="dian"></div>
            <div>{{ $store.getters.config.data_show_center_vice_title_7 }}</div>
          </div>
          <div ref='CenterTwo' id="CenterTwo" class="center-two"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import 'echarts-gl';
import {useStore} from "vuex";
import flights from '@/assets/flights.json'

export default {
  name: 'EarthMap',
  data() {
    return {
      width: 990,
      height: 55,
      topdate: '',
      topval: '',
      config1: {
        header: ['初值', '终值'],
        data: [
          ['108.66', '108.66'],
          ['156.04', '156.04'],
          ['127.88', '127.88'],
        ]
      },
      config2: {
        header: ['初值', '终值'],
        data: [
          ['108.66', '108.66'],
          ['156.04', '156.04'],
          ['127.88', '127.88'],
        ]
      },
      config3: {
        header: ['初值', '终值'],
        data: [
          ['108.66', '108.66'],
          ['156.04', '156.04'],
          ['127.88', '127.88'],
        ]
      },
      store: null,
      xdata: [],
      ydata1: [],
      ydata2: [],
      ydata3: [],
      xdataRes: [],
      ydata1Res: [],
      ydata2Res: [],
      ydata3Res: [],
      dataIndex: 0,
      topnum: 0,
      configHeader: [],
      jkData: [],
      ckData: [],
      jckData: [],
      configNum: 1
    }
  },
  methods: {
    getLoadEcharts() {
      var myChart = echarts.init(document.getElementById("earthMap"), 'dark', {
        width: document.getElementById("allBoxRef").offsetWidth * 0.5,
        height: window.outerHeight * 0.75
      });

      var lines = flights.features.filter(function (airline) {
        return airline.geometry.type == 'LineString' && airline.geometry.coordinates.length == 2;
      }).map(function (airline) {
        return airline.geometry.coordinates;
      });

      var points = flights.features.filter(function (dataItem) {
        return dataItem.geometry.type == 'Point' && dataItem.properties.country;
      }).map(function (airline) {
        return {
          name: airline.properties.country,
          value: airline.geometry.coordinates
        };
      });

      var option = {
        backgroundColor: '',
        globe: {
          baseTexture: require('@/assets/img/bj-dt-1.jpg'),
          heightTexture: require('@/assets/img/bj-dt-3.jpeg'),
          displacementScale: 0.05,
          globeRadius: 75,
          globeOuterRadius:150,
          shading: 'realistic',
          environment: false,
          postEffect: {
            enable: true,
            SSAO: {
              enable: true,
              radius: 3,
              intensity: 1,
              quality: 'high'
            }
          },
          viewControl: {
            projection: "perspective",
          },
          realisticMaterial: {
            roughness: 0.3
          },
          light: {
            ambient: {
              intensity: 0.4,
              shadow: true
            }
          },
          layers: [
            {
              type: 'overlay',
              texture: require('@/assets/img/bj-dt-2.png'),
              shading: 'lambert',
              distance: 2.7
            }
          ],
          silent: true
        },
        series: [
          {
            name: "lines3D",
            type: 'lines3D',
            coordinateSystem: 'globe',
            blendMode: 'lighter',
            lineStyle: {
              opacity: 0.2,
              width: 2,
              color: "#666"
            },
            effect: {
              show: true,
              trailWidth: 3,
              trailLength: 0.3,
              trailOpacity: 0.8,
              trailColor: "#FFF"
            },
            data: lines
          },
          {
            name: "scatter3D",
            type: "scatter3D",
            coordinateSystem: 'globe',
            blendMode: 'lighter',
            symbolSize: 10,
            itemStyle: {
              opacity: 1,
              borderWidth: 1,
              borderColor: 'rgba(255,255,255,0.8)'
            },
            label: {
              show: true,
              backgroundColor: 'rgba(0,0,0,0.5)',
              borderColor: '#000000',
              borderWidth: 1,
              padding: 10,
              position: 'top',
              formatter: "{b}",
              textStyle: {
                borderWidth: 1,
                fontSize: 18,
                fontWeight: 900,
                color: "#FFF"
              }
            },
            data: points
          }
        ]
      };

      myChart.setOption(option);
    },
    loadCenterOne() {
      this.xdataRes = Object.keys(this.store.getters['config'].data_show_center_vice_data_2_0);
      this.ydata1Res = Object.values(this.store.getters['config'].data_show_center_vice_data_2_0);
      this.ydata2Res = Object.values(this.store.getters['config'].data_show_center_vice_data_2_1);
      this.ydata3Res = Object.values(this.store.getters['config'].data_show_center_vice_data_2_2);

      this.xdata = JSON.parse(JSON.stringify(this.xdataRes));
      this.xdata = this.xdata.splice(0, (this.xdataRes.length / 2));

      this.ydata1 = JSON.parse(JSON.stringify(this.ydata1Res));
      this.ydata1 = this.ydata1.splice(0, (this.ydata1Res.length / 2));

      this.ydata2 = JSON.parse(JSON.stringify(this.ydata2Res));
      this.ydata2 = this.ydata2.splice(0, (this.ydata2Res.length / 2));

      this.ydata3 = JSON.parse(JSON.stringify(this.ydata3Res));
      this.ydata3 = this.ydata3.splice(0, (this.ydata3Res.length / 2));

      var centerOne = echarts.init(document.getElementById("CenterOne"), 'dark');
      var option = {
        backgroundColor: '',
        legend: {
          itemGap: 60,
          itemWidth: 11,
          bottom: 0,
          data: ['进出口', '进口', '出口']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '10%',
          containLabel: true
        },
        xAxis: {
          boundaryGap: false,
          data: ['202101', '202102', '202103', '202104', '202105']
        },
        yAxis: {
          type: 'value'
        },
        color: ['#09BCFF', '#09FFC4', '#FFE609',],
        series: [
          {
            name: this.store.getters['config'].data_show_center_vice_data_2_g[0],
            type: 'line',
            stack: 'Total',
            data: this.ydata1,
            areaStyle: {
              color: "#FFE609",
              opacity: 0.3
            },
            lineStyle: {
              type: 'dashed'
            },
            symbol: 'circle',
            symbolSize: 10
          },
          {
            name: this.store.getters['config'].data_show_center_vice_data_2_g[1],
            type: 'line',
            stack: 'Total',
            data: this.ydata2,
            areaStyle: {
              color: "#09BCFF",
              opacity: 0.3
            },
            lineStyle: {
              type: 'dashed'
            },
            symbol: 'circle',
            symbolSize: 10
          },
          {
            name: this.store.getters['config'].data_show_center_vice_data_2_g[2],
            type: 'line',
            stack: 'Total',
            data: this.ydata3,
            areaStyle: {
              color: "#09FFC4",
              opacity: 0.3
            },
            lineStyle: {
              type: 'dashed'
            },
            symbol: 'circle',
            symbolSize: 10
          }
        ]
      };
      centerOne.setOption(option);

      let that = this;
      setInterval(function () {
        if (that.dataIndex > that.xdataRes.length / 2) {
          that.dataIndex = 0
        }
        if (that.topnum > Object.keys(that.store.getters.config.data_show_center_vice_data_1).length) {
          that.topnum = 0
        }
        if (that.configNum > Object.keys(that.store.getters.config.center_tab_data_jck).length - 4) {
          that.configNum = 0
        }


        var xdataRes2 = JSON.parse(JSON.stringify(that.xdataRes));
        xdataRes2.push(...xdataRes2);
        that.xdata = xdataRes2.splice(that.dataIndex, (that.xdataRes.length / 2));

        var ydata1Res2 = JSON.parse(JSON.stringify(that.ydata1Res));
        ydata1Res2.push(...ydata1Res2);
        that.ydata1 = ydata1Res2.splice(that.dataIndex, (that.ydata1Res.length / 2));

        var ydata2Res2 = JSON.parse(JSON.stringify(that.ydata2Res));
        ydata2Res2.push(...ydata2Res2);
        that.ydata2 = ydata2Res2.splice(that.dataIndex, (that.ydata2Res.length / 2));

        var ydata3Res2 = JSON.parse(JSON.stringify(that.ydata2Res));
        ydata3Res2.push(...ydata3Res2);
        that.ydata3 = ydata3Res2.splice(that.dataIndex, (that.ydata3Res.length / 2));

        centerOne.setOption({
          xAxis: [
            {
              type: 'category',
              boundaryGap: false,
              data: that.xdata
            }
          ],
          series: [
            {
              data: that.ydata1
            },
            {
              data: that.ydata2
            },
            {
              data: that.ydata2
            }
          ]
        });
        var tmptopnum = 0;
        for (var i in that.store.getters.config.data_show_center_vice_data_1) {
          if (tmptopnum == that.topnum) {
            that.topdate = i;
            that.topval = that.store.getters.config.data_show_center_vice_data_1[i];
            break;
          }
          tmptopnum++;
        }

        that.tabDataFormat(that.configNum);

        that.configNum++;
        that.dataIndex++;
        that.topnum++;
      }, 4000);
    },
    loadCenterTwo() {
      var centerTwo = echarts.init(document.getElementById("CenterTwo"), 'dark');
      var dataTwo = Object.values(this.store.getters['config'].data_show_center_vice_date_7);
      var MaxData = JSON.parse(JSON.stringify(dataTwo)).sort().reverse()[0]
      var option = {
        backgroundColor: '',
        grid: {
          top: 20,
          left: 0,
          right: 30,
          height: '100%',
          containLabel: true
        },
        xAxis: {
          show: false,
          type: 'value',
          min: 0,
          max: 50 + parseInt(MaxData)
        },
        yAxis: {
          type: 'category',
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          data: Object.keys(this.store.getters['config'].data_show_center_vice_date_7)
        },
        color: ['#00b7ee', '#55cc8d', '#ffbc47', '#8d7dff', '#fc6767'],
        series: [
          {
            type: 'bar',
            showBackground: true,
            colorBy: 'data',
            itemStyle: {
              borderRadius: 10,
            },
            barCategoryGap: '20',
            data: dataTwo
          }
        ]
      };
      centerTwo.setOption(option);
    },
    copyArrToArr(arr) {
      var arr2 = JSON.parse(JSON.stringify(arr));
      arr.push(...arr2);
      return arr;
    },
    getTabIdx(idx, tabnum) {
      if (idx <= 9) {
        return idx + tabnum;
      }
      return tabnum;
    },
    tabDataFormat(idx) {
      let data1 = [];
      let data2 = [];
      let data3 = [];

      data1.push(Object.values(this.store.getters['config'].center_tab_data_jck)[this.getTabIdx(idx, 0)].split(','));
      data2.push(Object.values(this.store.getters['config'].center_tab_data_jk)[this.getTabIdx(idx, 1)].split(','));
      data3.push(Object.values(this.store.getters['config'].center_tab_data_ck)[this.getTabIdx(idx, 2)].split(','));
      data1.push(Object.values(this.store.getters['config'].center_tab_data_jck)[this.getTabIdx(idx + 1, 0)].split(','));
      data2.push(Object.values(this.store.getters['config'].center_tab_data_jk)[this.getTabIdx(idx + 1, 1)].split(','));
      data3.push(Object.values(this.store.getters['config'].center_tab_data_ck)[this.getTabIdx(idx + 1, 2)].split(','));
      data1.push(Object.values(this.store.getters['config'].center_tab_data_jck)[this.getTabIdx(idx + 2, 0)].split(','));
      data2.push(Object.values(this.store.getters['config'].center_tab_data_jk)[this.getTabIdx(idx + 2, 1)].split(','));
      data3.push(Object.values(this.store.getters['config'].center_tab_data_ck)[this.getTabIdx(idx + 2, 2)].split(','));
      this.config1.data = data1;
      this.config2.data = data2;
      this.config3.data = data3;
    },
    objFormat(obj) {
      let arr = [];
      for (var i in obj) {
        arr.push([i, obj[i]]);
      }
      return arr;
    }
  },
  mounted() {
    this.store = useStore();

    this.configHeader = this.copyArrToArr(Object.keys(this.store.getters['config'].center_tab_data_jk));

    this.config1['header'] = this.store.getters['config'].data_show_center_vice_date_4_1;
    this.config2['header'] = this.store.getters['config'].data_show_center_vice_date_5_1;
    this.config3['header'] = this.store.getters['config'].data_show_center_vice_date_6_1;

    this.tabDataFormat(0)

    for (var i in this.store.getters.config.data_show_center_vice_data_1) {
      this.topdate = i;
      this.topval = this.store.getters.config.data_show_center_vice_data_1[i];
      break;
    }


    this.getLoadEcharts();
    this.loadCenterOne();
    this.loadCenterTwo();
  }
}
</script>

<style lang="scss" scoped>
.center-div {
  position: relative;

  .earth-top-box {
    width: 230px;
    float: right;
    margin-right: 30px;

    .time-box {
      font-size: 18px;
      text-align: center;
    }

    .zhishu-box {
      width: 230px;
      height: 100px;

      .zhishu-box-son {
        width: 100%;
        color: #f6cc57;
        font-size: 24px;
        text-align: center;
        position: absolute;
        bottom: 15px;

      }
    }
  }

  .center-end {
    width: 100%;
    position: absolute;
    top: 40%;

    .center-svg-container {
      width: 100%;
      height: 55px;
      opacity: 0.6;
    }

    .center-one {
      width: 100%;
      height: 260px;
    }

    .center-lower {
      width: 100%;
      height: 240px;
      position: relative;

      .dv-border-box-10 {
        width: 90px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        position: absolute;
        top: 10px;
        left: 20px;
        color: #50fcfc;
      }

      .lower-left {
        width: 13%;
        text-align: right;
        line-height: 40px;
        position: absolute;
        bottom: 0px;
      }

      .lower-center {
        width: 60%;
        text-align: center;
        position: absolute;
        left: 123px;
        bottom: 0px;

        .data-div {
          width: 30%;

          .title {
            line-height: 36px;
          }

          table {
            width: 100%;
            line-height: 40px;
            border: 1px solid #1a67b5;

            th {
              background-color: #1a67b5;
            }
          }
        }
      }

      .lower-right {
        position: absolute;
        width: 25%;
        right: 0;
        bottom: 0;

        .title {
          font-size: 30px;
          line-height: 30px;

          .dian {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            color: chartreuse;
            background-color: chartreuse;
            margin-right: 13px;
            margin-top: 10px;
          }
        }

        .center-two {
          width: 95%;
          height: 200px;
        }

      }
    }
  }

}
</style>
