<template>
    <div class="text-box-1" :ref="ref">
        <svg class="dv-svg-container" :width="width" :height="height">
            <defs>
                <filter id="polygonbj" height="150%" width="150%" x="-25%" y="-25%">
                    <feMorphology operator="dilate" radius="2" in="SourceAlpha" result="thicken" />
                    <feGaussianBlur in="thicken" stdDeviation="3" result="blurred" />
                    <feFlood :flood-color="mergedColor[1]" result="glowColor" />
                    <feComposite in="glowColor" in2="blurred" operator="in" result="softGlowColored" />
                    <feMerge>
                        <feMergeNode in="softGlowColored" />
                        <feMergeNode in="SourceGraphic" />
                    </feMerge>
                </filter>
            </defs>

            //顶部折线
            <polyline :stroke="mergedColor[0]" class="text-box-cu"
                :points="`0,${height * 0.3},0,${height * 0.15},14,0,${width * 0.4},0,${width * 0.4 + 14},14,${width * 0.7},14,${width * 0.7 + 14},0,${width - 14},0,${width},14,${width},${height * 0.3}`" />

            <polyline :stroke="mergedColor[0]" class="text-box-cu"
                :points="`0,${height * 0.7},0,${height * 0.85},14,${height},${width * 0.5},${height},${width * 0.5 + 10},${height - 5},${width * 0.75},${height - 5},${width * 0.75 + 10},${height},${width - 14},${height},${width},${height - 14},${width},${height * 0.7}`" />

            <polygon
                :points="`${width * 0.5},${height - 4},${width * 0.75},${height - 4}, ${width * 0.5},${height},${width * 0.75},${height}`"
                :fill="mergedColor[1]" />

            <polygon opacity="0.4"
                :points="`0,${height - 14},0,${height * 0.15},14,0,${width * 0.4},0,${width * 0.4 + 14},14,${width * 0.7},14,${width * 0.7 + 14},0,${width - 14},0,${width},14,${width},${height - 14},0,${height - 14},0,${height * 0.85},14,${height},${width * 0.5},${height},${width * 0.5 + 10},${height - 5},${width * 0.75},${height - 5},${width * 0.75 + 10},${height},${width - 14},${height},${width},${height - 14},${width},${height - 14}`"
                :fill="mergedColor[2]" />
        </svg>

        <div class="border-box-content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import autoResize from '@jiaminghi/data-view/lib/mixin/autoResize'

import { deepMerge } from '@jiaminghi/charts/lib/util/index'

import { deepClone } from '@jiaminghi/c-render/lib/plugin/util'

import { fade } from '@jiaminghi/color'

export default {
    name: 'textBox',
    mixins: [autoResize],
    props: {
        color: {
            type: Array,
            default: () => ([])
        },
        title: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            ref: 'text-box-1',

            defaultColor: ['#3270b8', '#df9fff', '#0d1432'],

            mergedColor: []
        }
    },
    watch: {
        color() {
            const { mergeColor } = this

            mergeColor()
        }
    },
    methods: {
        mergeColor() {
            const { color, defaultColor } = this

            this.mergedColor = deepMerge(deepClone(defaultColor, true), color || [])
        },
        fade
    },
    mounted() {
        const { mergeColor } = this

        mergeColor()
    }
}
</script>
<style scoped>
.text-box-1 {
    position: relative;
    width: 100%;
    height: 100%;
}

.text-box-1 .dv-svg-container {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}

.text-box-1 .dv-svg-container polyline {
    fill: none;
}

.text-box-1 .border-box-content {
    position: relative;
    padding: 14px;
}

.text-box-1 .text-box-cu {
    stroke-width: 3px;
    stroke-linecap: round;
}
</style>