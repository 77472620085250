<template>
    <div>
        <div ref='RightTwo' id="RightTwo" style="width:100%;height:300px;"></div>
    </div>
</template>

<script>
import * as echarts from 'echarts'
import 'echarts-gl';
import { useStore } from "vuex";
export default {
    name: 'RightTwo',
    props: {},
    data() {
        return {
            store: null,
            xdata: [],
            ydata: [],
            xdataRes: [],
            ydataRes: [],
            dataIndex: 0
        }
    },
    methods: {
        getLoadEcharts() {

            this.xdataRes = Object.keys(this.store.getters['config'].data_show_right_echarts_2);
            this.ydataRes = Object.values(this.store.getters['config'].data_show_right_echarts_2);

            this.xdata = JSON.parse(JSON.stringify(this.xdataRes));
            this.xdata = this.xdata.splice(0, (this.xdataRes.length / 2));

            this.ydata = JSON.parse(JSON.stringify(this.ydataRes));
            this.ydata = this.ydata.splice(0, (this.ydataRes.length / 2));

            var myChart = echarts.init(document.getElementById("RightTwo"), 'dark');
            var option = {
                backgroundColor: '',
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: false,
                        data: this.xdata
                    }
                ],
                yAxis: [
                    {
                        type: 'value'
                    }
                ],
                color: ['#09deff'],
                series: [
                    {
                        name: 'Fake Data',
                        type: 'line',
                        smooth: true,
                        stack: 'a',
                        symbol: 'circle',
                        symbolSize: 5,
                        sampling: 'average',
                        areaStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: '#248291'
                                },
                                {
                                    offset: 1,
                                    color: '#10224d'
                                }
                            ])
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: this.ydata
                    }
                ]
            };
            myChart.setOption(option);

            let that = this;
            setInterval(function () {
                if (that.dataIndex > that.xdataRes.length / 2) {
                    that.dataIndex = 0
                }

                var xdataRes2 = JSON.parse(JSON.stringify(that.xdataRes));
                var ydataRes2 = JSON.parse(JSON.stringify(that.ydataRes));
                xdataRes2.push(...xdataRes2);
                ydataRes2.push(...ydataRes2);

                that.xdata = xdataRes2.splice(that.dataIndex, (that.xdataRes.length / 2));
                that.ydata = ydataRes2.splice(that.dataIndex, (that.ydataRes.length / 2));

                myChart.setOption({
                    xAxis: [
                        {
                            type: 'category',
                            boundaryGap: false,
                            data: that.xdata
                        }
                    ],
                    series: [
                        {
                            data: that.ydata
                        }
                    ]
                });
                that.dataIndex++;
            }, 4500);
        }
    },
    mounted() {
        this.store = useStore();
        this.getLoadEcharts();
    }
}
</script>

<style lang="scss" scoped>
</style>
