<template>
    <div class="custom-box-1" :ref="ref">
        <svg class="dv-svg-container" :width="width" :height="height">
            <defs>
                <filter id="polygonbj" height="150%" width="150%" x="-25%" y="-25%">
                    <feMorphology operator="dilate" radius="2" in="SourceAlpha" result="thicken" />
                    <feGaussianBlur in="thicken" stdDeviation="3" result="blurred" />
                    <feFlood :flood-color="mergedColor[1]" result="glowColor" />
                    <feComposite in="glowColor" in2="blurred" operator="in" result="softGlowColored" />
                    <feMerge>
                        <feMergeNode in="softGlowColored" />
                        <feMergeNode in="SourceGraphic" />
                    </feMerge>
                </filter>
            </defs>



            //顶部三角
            <polygon points="0,0 10,0 0,10" :fill="mergedColor[1]" />

            //顶部折线
            <polyline :stroke="mergedColor[0]"
                :points="`4, 14, 14, 4, ${width * 0.8}, 4 , ${width * 0.9}, 24 , ${width},24`" />

            //顶部加粗折线
            <polyline :stroke="mergedColor[1]"
                :points="`${width * 0.7}, 3, ${width * 0.8}, 3, ${width * 0.9}, 23 , ${width * 0.92},23`"
                class="border-custom" />


            //右上角加粗
            <polyline :stroke="mergedColor[1]"
                :points="`${width * 0.95}, 23, ${width}, 23, ${width},${height * 0.05 + 23}`" class="border-custom" />

            //左边线
            <polyline :stroke="mergedColor[0]" :points="`4, 14, 4, ${height}`" />

            //左底角
            <polyline :stroke="mergedColor[1]" :points="`4,${height * 0.97}, 4, ${height} , 24, ${height}`"
                class="border-custom" />

            //右边线
            <polyline :stroke="mergedColor[0]" :points="`${width}, 23, ${width}, ${height} `" />
            //底部线
            <polyline :stroke="mergedColor[0]"
                :points="`4, ${height}, 24, ${height},${width * 0.1}, ${height - 6}, ${width * 0.9},${height - 6}, ${width * 0.9 + 24}, ${height},${width}, ${height} `" />
            //底部加粗线
            <polyline :stroke="mergedColor[1]" :points="`${width * 0.1 + 4},${height},  ${width * 0.9 - 4}, ${height}`"
                class="border-custom" />


            //标题顶部三角
            <polygon points="30,20 43,20 30,43" :fill="mergedColor[1]" />
            //标题顶部折线
            <polyline :stroke="mergedColor[0]" :filter="`url(#polygonbj)`"
                :points="`34, 49, 49, 25, ${width * 0.8}, 25 , ${width * 0.8}, 69 , 34,69,34,49`" />

            <text :x="`${width * 0.445}`" y="50" fill="#fff" font-size="22" text-anchor="middle"
                dominant-baseline="middle">
                {{ title }}
            </text>

            //背景
            <polygon opacity="0.1"
                :points="`4, 14, 14, 4, ${width * 0.8}, 4 , ${width * 0.9}, 24 , ${width},24,${width}, 23, ${width}, ${height},0, ${height}`"
                :fill="mergedColor[1]" />
        </svg>

        <div class="border-box-content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import autoResize from '@jiaminghi/data-view/lib/mixin/autoResize'

import { deepMerge } from '@jiaminghi/charts/lib/util/index'

import { deepClone } from '@jiaminghi/c-render/lib/plugin/util'

import { fade } from '@jiaminghi/color'

export default {
    name: 'CustomBox',
    mixins: [autoResize],
    props: {
        color: {
            type: Array,
            default: () => ([])
        },
        title: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            ref: 'custom-box-1',

            defaultColor: ['#3270b8', '#df9fff'],

            mergedColor: []
        }
    },
    watch: {
        color() {
            const { mergeColor } = this

            mergeColor()
        }
    },
    methods: {
        mergeColor() {
            const { color, defaultColor } = this

            this.mergedColor = deepMerge(deepClone(defaultColor, true), color || [])
        },
        fade
    },
    mounted() {
        const { mergeColor } = this

        mergeColor()
    }
}
</script>
<style scoped>
.custom-box-1 {
    position: relative;
    width: 100%;
    height: 100%;
}

.custom-box-1 .dv-svg-container {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}

.custom-box-1 .dv-svg-container polyline {
    fill: none;
}

.custom-box-1 .border-box-content {
    position: relative;
    width: 100%;
    padding: 90px 35px 0 35px;
}

.border-custom {
    stroke-width: 3px;
    stroke-linecap: round;
}
</style>