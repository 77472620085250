<template>
  <div id="index" ref="appRef">
    <div class="bg">
      <dv-loading v-if="loading" class="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div class="topdiv">
          <div class="title1">
            {{ $store.getters.config.data_show_title }}
            <div class="title2">
              {{ $store.getters.config.data_show_title2 }}
            </div>
          </div>
          <div class="title3">
            {{ curTime }}
          </div>
        </div>

      </div>
      <div class="d-flex jc-between px-2" id="allBoxRef">
        <div class="d-flex aside-width" style="width:25%">
          <custom-box :title="$store.getters.config.data_show_left_title">
            <div class="top1">
              <div class="title d-flex aside-width">
                <div class="dian"></div>
                <div>{{ leftData1Title }}</div>
              </div>
              <div class="content1">
                <img src="@/assets/img/zhuan1.png"/>
                <div class="con-text">{{ leftData1Data }}</div>
              </div>
            </div>
            <div class="division"></div>
            <div class="left-2">
              <div class="title d-flex aside-width">
                <div class="dian"></div>
                <div>{{ leftData2Title }}</div>
              </div>
              <div class="content2 d-flex aside-width">
                <div class="t1">{{ $store.getters.config.data_show_left_vice_title_2_2 }}</div>
                <text-box :class="leftData4Class">
                  {{ leftData4Data }}
                  <span style=" font-size:20px; margin-left:5px; ">%</span>
                </text-box>
              </div>
            </div>
            <div class="left-3">
              <div class="title d-flex jc-between px-2">
                <div class="t1">{{ $store.getters.config.data_show_left_vice_title_3 }}</div>
                <div class="t2">{{ leftData2Data }}</div>
              </div>
              <div class="content1">
                <dv-percent-pond :config="config1" style="width:100%;height:30px;"
                                 v-if="$store.getters.config.onLoadStatus == true"/>
              </div>
            </div>
            <div class="left-3">
              <div class="title d-flex jc-between px-2">
                <div class="t1">{{ $store.getters.config.data_show_left_vice_title_4 }}</div>
                <div class="t2">{{ leftData3Data }}</div>
              </div>
              <div class="content1">
                <dv-percent-pond :config="config2" style="width:100%;height:30px;"
                                 v-if="$store.getters.config.onLoadStatus == true"/>
              </div>
            </div>
            <div class="division"></div>
            <div class="left-2" style="margin-top:25px;">
              <div class="title d-flex aside-width">
                <div class="dian"></div>
                <div>{{ $store.getters.config.data_show_left_vice_title_5 }}</div>
              </div>
              <div class="content2">
                <left-three v-if="$store.getters.config.onLoadStatus == true"></left-three>
              </div>
            </div>
          </custom-box>
        </div>
        <div class="d-flex aside-width" style="width:50%">
          <earth-map v-if="$store.getters.config.onLoadStatus == true"></earth-map>
        </div>
        <div class="d-flex aside-width" style="width:25%">
          <custom-box :title="$store.getters.config.data_show_right_title">
            <div class="right1 d-flex jc-between px-2">
              <div class="title">
                <div>{{ rightTopDate }}</div>
                <div>{{ $store.getters.config.data_show_right_vice_title_1 }}</div>
              </div>
              <div class="content">
                <text-box>
                  {{ rightTopVal }}
                </text-box>
              </div>
            </div>
            <div class="division"></div>
            <div class="right2">
              <div class="title d-flex aside-width">
                <div class="dian"></div>
                <div>{{ $store.getters.config.data_show_right_vice_title_2 }}</div>
              </div>
              <div class="content2">
                <right-one v-if="$store.getters.config.onLoadStatus == true"></right-one>
              </div>
            </div>
            <div class="division"></div>
            <div class="right2">
              <div class="title d-flex aside-width">
                <div class="dian"></div>
                <div>{{ $store.getters.config.data_show_right_vice_title_3 }}</div>
              </div>
              <div class="content2">
                <right-two v-if="$store.getters.config.onLoadStatus == true"></right-two>
              </div>
            </div>
          </custom-box>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import {
  defineComponent,
  ref,
  onMounted,
  onUnmounted
} from 'vue'
import useDraw from '../utils/useDraw'
import customBox from '@/components/customBox/index'
import EarthMap from "@/components/earthMap/index"
import textBox from "@/components/textBox/index"
import LeftThree from "@/components/leftThree/index"
import RightOne from "@/components/rightOne/index"
import RightTwo from "@/components/rightTwo/index"

import {getConfig} from "@/api/system"
import {useStore} from "vuex"

export default defineComponent({
  components: {
    customBox,
    EarthMap,
    textBox,
    LeftThree,
    RightOne,
    RightTwo
  },
  data() {
    return {
      store: null,
      rightTopDate: '202207',
      rightTopVal: '98.8',
      topnum: 0,
      leftData1Title: '欧洲',
      leftData1Data: '5917.47',
      leftData1Num: 0,
      //区域上期对比
      leftData2Title: '欧洲',
      leftData2Data: '0.00',
      leftData3Data: '0.00',
      leftData4Class: '',
      leftData4Data: '0',
      config1: {
        value: 100,
        borderGap: 5,
        formatter: ''
      },
      config2: {
        value: 100,
        borderGap: 5,
        formatter: ''
      },
      curTime: ''
    }
  },
  methods: {
    setData(list, num, keyname, valname) {
      var that = this;
      var tmptopnum = 0;
      for (var i1 in list) {
        if (tmptopnum == num) {
          that[keyname] = i1;
          that[valname] = list[i1];
          break;
        }
        tmptopnum++;
      }
    },
    checkTime(i) {
      console.log(i)
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    },
    showtime() {
      let nowdate = new Date();
      let year = nowdate.getFullYear(),
          month = nowdate.getMonth() + 1,
          date = nowdate.getDate(),
          h = nowdate.getHours(),
          m = nowdate.getMinutes(),
          s = nowdate.getSeconds();
      date = this.checkTime(date);
      month = this.checkTime(month);
      h = this.checkTime(h);
      m = this.checkTime(m);
      s = this.checkTime(s);
      this.curTime = year + "-" + month + "-" + date + "-" + " " + h + ":" + m + ":" + s;
    }
  },
  mounted() {
    this.store = useStore();
    let that = this;

    getConfig().then(res => {
      res.data.onLoadStatus = true;
      that.store.dispatch('setConfig', res.data);

      that.setData(that.store.getters.config.dp_left_data_1, 0, 'leftData1Title', 'leftData1Data');
      that.setData(that.store.getters.config.dp_left_data_2, 0, 'leftData2Title', 'leftData2Data');
      that.setData(that.store.getters.config.dp_left_data_3, 0, 'leftData3Title', 'leftData3Data');

      that.config1.value = parseInt(that.leftData2Data / 8000 * 100);
      that.config2.value = parseInt(that.leftData3Data / 8000 * 100);

      if (that.leftData2Data > that.leftData3Data) {
        that.leftData4Class = 'red'
      } else {
        that.leftData4Class = ''
      }
      that.leftData4Data = ((that.leftData3Data - that.leftData2Data) / that.leftData2Data * 100).toFixed(2)

      that.setData(that.store.getters.config.data_show_right_vice_data_1, 0, 'rightTopDate', 'rightTopVal');
    })


    setInterval(function () {
      if (that.topnum > Object.keys(that.store.getters.config.data_show_right_vice_data_1).length) {
        that.topnum = 0
      }
      if (that.leftData1Num > Object.keys(that.store.getters.config.dp_left_data_1).length) {
        that.leftData1Num = 0
      }

      //左侧顶部数据1
      that.setData(that.store.getters.config.dp_left_data_1, that.leftData1Num, 'leftData1Title', 'leftData1Data');
      //左侧顶部数据2
      that.setData(that.store.getters.config.dp_left_data_2, that.leftData1Num, 'leftData2Title', 'leftData2Data');
      //左侧顶部数据3
      that.setData(that.store.getters.config.dp_left_data_3, that.leftData1Num, 'leftData3Title', 'leftData3Data');

      that.config1.value = parseInt(that.leftData2Data / 8000 * 100);
      that.config2.value = parseInt(that.leftData3Data / 8000 * 100);

      if (that.leftData2Data > that.leftData3Data) {
        that.leftData4Class = 'red'
      } else {
        that.leftData4Class = ''
      }
      that.leftData4Data = ((that.leftData3Data - that.leftData2Data) / that.leftData2Data * 100).toFixed(2)

      //右侧顶部数据
      that.setData(that.store.getters.config.data_show_right_vice_data_1, that.topnum, 'rightTopDate', 'rightTopVal');

      that.leftData1Num++;
      that.topnum++;
    }, 3000);

    this.showtime();

    setInterval(function () {
      that.showtime();
    }, 1000);
  },
  setup() {
    const loading = ref(true)

    const {appRef, calcRate, windowDraw, unWindowDraw} = useDraw()

    // 生命周期
    onMounted(() => {
      cancelLoading()
      windowDraw()
      calcRate()
    })

    onUnmounted(() => {
      unWindowDraw()
    })

    const cancelLoading = () => {
      setTimeout(() => {
        loading.value = false
      }, 500)
    }

    return {
      loading,
      appRef
    }
  }
})
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';
</style>