import axios from 'axios'
import store from '@/store'
import Setting from '@/config/setting'

const instance = axios.create({
    baseURL: Setting.https,
    timeout: 60000
})
const defaultOpt = { login: true }

function baseRequest(options) {
    const token = store.getters.token
    const headers = options.headers || {}
    if (token) {
        headers['X-Token'] = token
        options.headers = headers
    }
    return new Promise((resolve, reject) => {
        instance(options).then(res => {
            const data = res.data || {}
            if (res.status !== 200) {
                return reject({ message: '请求失败', res, data })
            }

            if (data.code === 1) {
                return resolve(data, res)
            } else {
                return reject({ message: data.msg, res, data })
            }
        }).catch(message => reject({ message }));
    })
}
const request = ['post', 'put', 'patch', 'delete'].reduce((request, method) => {
    request[method] = (url, data = {}, options = {}) => {
        return baseRequest(
            Object.assign({ url, data, method }, defaultOpt, options)
        )
    }
    return request
}, {});

['get', 'head'].forEach(method => {
    request[method] = (url, params = {}, options = {}) => {
        return baseRequest(
            Object.assign({ url, params, method }, defaultOpt, options)
        )
    }
})

export default request